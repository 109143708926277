<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-2">
                                <h4 class="card-title">All admins</h4>
                            </div>
                            <div class="col-md-10 text-right">
                                <b-button variant="success">
                                    <i class="ri-file-excel-2-line  align-middle mr-2"></i> Export
                                </b-button>
                            </div>

                        </div>

                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :items="adminData"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                            >
                                <template v-slot:cell(actions)="{ item}">
                                    <b-button v-b-tooltip.hover title="View details"  class="actionBut" @click.prevent="view(item)"  variant="info" >
                                        <i class="ri-eye-fill align-middle "></i>
                                    </b-button>

                                    <b-button v-b-tooltip.hover title="Edit" class="actionBut" variant="success" @click.prevent="edit(item)">
                                        <i class="ri-edit-fill align-middle "></i>
                                    </b-button>
                                    <b-button v-b-tooltip.hover title="Delete" class="actionBut" variant="danger" v-b-modal.modal-delete @click.prevent="deleteIte(item)">
                                        <i class="ri-delete-bin-2-fill align-middle "></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <b-modal ref="modal-delete" id="modal-delete" hide-footer title="Delete Admin">
                        <p>Do you really want to delete this Admin ?</p>
                        <div style="display: flex; justify-content: flex-end">
                            <b-button class="mr-2" variant="danger" @click.prevent="hidemodaldelete">Cancel</b-button>
                            <b-button variant="primary" @click.prevent="deleteAdm">Delete</b-button>
                        </div>
                    </b-modal>
                    <div class="lodader__content" v-if="loader">
                        <b-spinner style="width: 3rem; height: 3rem" class="loader" variant="success" label="Spinning"></b-spinner>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
    
</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import dateFormat from "dateformat";

    import { tableData } from "./dataAdvancedtable";
    import {axios_delete, axios_get} from "../../../helpers/helper";

    /**
     * Advanced table component
     */
    export default {
        page: {
            title: "View Admins",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout, PageHeader },
        data() {
            return {
                tableData: tableData,
                adminData:[],
                name:null,
                loader: false,
                title: "View all Admins",
                items: [
                    {
                        text: "Admin",
                        href: "/"
                    },
                    {
                        text: "View Admins",
                        active: true
                    }
                ],
                totalRows: 1,
                currentPage: 1,
                del: null,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "created_at",
                sortDesc: false,
                fields: [
                    { key: "name", sortable: true },
                    //{ key: "surname", sortable: true },
                    { key: "username", sortable: true },
                    { key: "email", sortable: true },
                    { key: "country.country_name", sortable: true, label: "Country Name" },
                    { key: "created_at", sortable: true },
                    { key: "actions", sortable: false }
                ]
            };
        },
        computed: {
            /**
             * Total no. of records
             */
            rows() {
                return this.adminData.length;
            }
        },
        mounted() {
            this.totalRows = this.items.length;
            axios_get("/user",{},(de)=>{
                console.log(de)
                this.adminData=de.filter(e=>{return e.role=='ADMIN'})
                this.adminData = this.adminData.map(e=>{
                    e.created_at = new Date(e.created_at)
                    e.created_at = dateFormat(e.created_at, "longDate")
                    return e;
                })
                this.adminData=this.adminData.sort((a, b)=>{
                    if(a.id < b.id) return 1;
                    else return -1;
                })
                console.log(this.adminData)
                },(e)=>{
                    console.log("error",e);
                },()=>{
                    console.log("finishing");
                }
            );
        },
        methods: {
            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            hideModal() {
                this.$refs['my-modal'].hide()
            },
            view(e){
                console.log(e)
                window.location.href = "admin/"+e.id
            },
            edit(e){
                console.log(e)
                window.location.href = "admin/edit-admin/"+e.id
            },
            deleteIte(e){
                this.del = e
            },
            hidemodaldelete(){
                this.$refs['modal-delete'].hide()
            },
            deleteAdm(){
                this.loader = true
                this.$refs['modal-delete'].hide()
                axios_delete('/user/'+this.del.id, {}, ()=>{
                    axios_get("/user",{},(d)=>{
                        console.log(d)
                        this.adminData=d.filter(e=>{return e.role=='ADMIN'})
                        this.adminData=this.adminData.sort((a, b)=>{
                            if(a.name < b.name) return -1;
                            else return 1;
                        })
                        this.adminData = this.adminData.map(e=>{
                            e.created_at = new Date(e.created_at)
                            e.created_at = dateFormat(e.created_at, "longDate")
                            return e;
                        })
                        console.log(this.adminData)
                        },(e)=>{
                            console.log("error",e);
                        },()=>{
                            console.log("finishing");
                            this.loader = false
                        }
                    );
                }, (e)=>{
                    console.log('error', e)
                }, ()=>{
                    console.log('finish')
                    this.loader = false
                })
            }

        }
    };
</script>

<style scoped>
    .actionBut{
        margin-left:15px;
    }

    .lodader__content{
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .1);
        width: 100%;
        height: 100%;
    }

    .loader{
        position: relative;
        left: 50%;
        top: 50%;
    }
</style>